/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Btn from "../../../components/Btn";
import { useNavigate } from "react-router-dom";
import { api, errHandler } from "../../../utilities/API";
import { toast } from "react-toastify";

const PropertyTypes = () => {
  const navigate = useNavigate();
  const [list, setlist] = useState();
  const [open, setOpen] = useState();
  
  useEffect(() => {
    loadPropertyTypes();
  }, []);

  const loadPropertyTypes = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/properties/types', { headers: { Authorization: `Bearer ${token}` } });
      setlist(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadPropertyTypes());
    }
  };

  const deleteType = async () => {
    try {
      setOpen();
      const laoding = toast.loading("Please wait...");
      const token = sessionStorage.getItem('token');
      const res = await api.delete('/admin/properties/types/delete?id=' + open, { headers: { Authorization: `Bearer ${token}` } });
      toast.update(laoding, { render: res.data.title, position: 'bottom-center', type: "success", isLoading: false, autoClose: true });
      loadPropertyTypes();
    } catch (err) {
      await errHandler(err);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    pb: 2
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body1" component="h6" className="text-danger">
            Confirm To Delete The Property Type?
          </Typography>
          <Typography className="text-secondary" sx={{ my: 2 }} variant="body2" component="p">
            You can only delete this type if it's not associated with any properties.
          </Typography>
          <div className="text-right">
            <Button variant="contained" color="error" onClick={deleteType}>
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
      <BreadCrumbs
        links={[{ label: "Dashboard", href: "/dashboard" }]}
        current="Property Types"
      />
      <br />
      <div className="d-flex align-items-center justify-content-between">
        <Typography variant="h5" component="h5">
          All Property Types
        </Typography>
        <Btn variant="contained" onClick={() => navigate('new')}>New</Btn>
      </div>
      <br />
      {
        !list
        ?
        <div style={{ display: 'grid', gridTemplateColumns: '33fr 33fr 33fr', gridColumnGap: 20 }}>
            {[1,1,1,1,1,1,1,1,1].map((_, index) => {
              return <Skeleton height={50} key={index} />
            })}
        </div>
        :
        list.length === 0
        ?
        <Typography variant="h5" component="h5" align="center">
          No Property Type is Added
        </Typography>
        :
        <table className="table">
          <thead>
            <tr>
            <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  Sr.No
                </Typography>
              </th>
              <th className="text-capitalize" colSpan={2}>
                <Typography variant="body2" fontWeight={500} component="p">
                  Label
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((val, index) => (
              <tr key={index}>
                <td>
                  <Typography variant="body2" fontWeight={300} component="p">
                    {index + 1}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" fontWeight={300} component="p">
                    {val.label}
                  </Typography>
                </td>
                <td>
                  <Typography onClick={() => setOpen(val.id)} variant="body2" fontWeight={300} component="p" className="text-danger pointer">
                    Delete
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  );
};

export default PropertyTypes;
