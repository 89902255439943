import React from "react";
import LogoImage from '../assets/images/logo.png';
import LogoLightImage from '../assets/images/logo-light.png';

const Logo = ({ theme }) => {
  if (theme && theme === 'light') {
    return (
      <img
        src={LogoLightImage}
        alt="Logo"
        className="logo"
      />
    );  
  }
  return (
    <img
      src={LogoImage}
      alt="Logo"
      className="logo"
    />
  );
};

export default Logo;
