/* eslint-disable react-hooks/exhaustive-deps */
import {
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { api, errHandler } from "../../../utilities/API";
import Btn from "../../../components/Btn";
import Message from "../../../components/Message";
import { toast } from "react-toastify";

const TermsofUse = () => {
  const validator = require('validator');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();
  const [policy, setContent] = useState();
  const [points, setPoints] = useState([]);
  const isMounted = useRef(false);
  
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true; // Mark as mounted
      loadContent();
    }
  }, []);

  const loadContent = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/content/terms_of_use', { headers: { Authorization: `Bearer ${token}` } });
      setContent(res.data?.data);
      setPoints(JSON.parse(res.data?.data.points));
    } catch (err) {
      await errHandler(err);
    }
  };

  const isValid = (content) => {
    if (validator.isEmpty(content)) {
      setAlert({
        title: 'Content is required!',
        message: 'Please enter your content.',
        type: 'warning',
      }
      );
      return false;
    }

    return true;
  };

  const onUpdateContent = async (e) => {
    e.preventDefault();
    const content = e.target['content'].value;
    const validation = isValid(content);
    if (validation) {
      setLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const res = await api.put('/admin/content/terms_of_use/update', { content, points: JSON.stringify(points) }, { headers: { Authorization: `Bearer ${token}` } });
        toast.success(res.data.message);
        loadContent();
      } catch (err) {
        await errHandler(err);
      }
      setLoading(false);
    }
  };

  if (!policy) {
    return (
      <>
        <Skeleton height={50} width={'50%'} />
        <Skeleton height={50} width={'30%'} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} width={'20%'} className="ml-auto" />
      </>
    )
  }

  return (
    <div>
      <BreadCrumbs
        links={[
          { label: "Dashboard", href: "/dashboard" },
        ]}
        current="User Terms"
      />
      <br />
      <div className="container border p-4 rounded">
        <form onSubmit={onUpdateContent}>
          <Typography variant="h5" component="h5">
            User Terms Content
          </Typography>
          <br />
          {
            alert && (
              <>
                <Message alert={alert} />
                <br />
              </>
            )
          }
          <fieldset>
            <TextField defaultValue={policy.content} multiline rows={15} label="User Terms Content" variant="outlined" className="mb-4" name="content" fullWidth required />
            {
              points.map((point, index) => {
                return <TextField onChange={(e) => {
                  if (e.target.value === '') {
                    setPoints(points.filter((item, i) => i !== index))
                  }else {
                    setPoints(points.map((item, i) => i === index ? e.target.value : item))
                  }
                }} multiline rows={3} key={index} value={point} label={`User Term Point (${index+1})`} variant="outlined" className="mb-4" name="point" fullWidth required />
              })
            }
            <div className="text-right">
              <Btn onClick={() => setPoints(() => [...points, "New Point..."])} type="button" className="text-right mr-3" size="large" variant="contained">Add Point</Btn>
              <Btn loading={loading} type="submit" className="text-right" size="large" variant="contained">Update Content</Btn>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default TermsofUse;
