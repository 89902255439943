/* eslint-disable react-hooks/exhaustive-deps */
import {
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { api, errHandler } from "../../../utilities/API";

const AdminQueries = () => {
  const [list, setlist] = useState();
  
  useEffect(() => {
    loadQueries();
  }, []);

  const loadQueries = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/queries/listing', { headers: { Authorization: `Bearer ${token}` } });
      setlist(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadQueries());
    }
  };

  return (
    <div>
      <BreadCrumbs
        links={[{ label: "Dashboard", href: "/dashboard" }]}
        current="Queries"
      />
      <br />
      <Typography variant="h5" component="h5">
        Admin Queries
      </Typography>
      <br />
      {
        !list
        ?
        <div style={{ display: 'grid', gridTemplateColumns: '33fr 33fr 33fr', gridColumnGap: 20 }}>
            {[1,1,1,1,1,1,1,1,1].map((_, index) => {
              return <Skeleton height={50} key={index} />
            })}
        </div>
        :
        list.length === 0
        ?
        <Typography variant="h5" component="h5" align="center">
          No Query Founded
        </Typography>
        :
        <table className="table table-bordered">
          <thead>
            <tr>
            <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  Sr.No
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  Name
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  Email
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  Message
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((val, index) => (
              <tr key={index}>
                <td>
                  <Typography variant="body2" fontWeight={300} component="p">
                    {index + 1}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" fontWeight={300} component="p">
                    {val.contact_person_name}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" fontWeight={300} component="p">
                    {val.contact_person_email}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" fontWeight={300} component="p">
                    {val.contact_person_message}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  );
};

export default AdminQueries;
