export function amountFormat(amount) {
    if (amount < 1000) {
        return amount;
    } else if (amount < 1000000) {
        return (amount / 1000).toFixed(1) + 'K';
    } else if (amount < 1000000000) {
        return (amount / 1000000).toFixed(1) + 'M';
    } else if (amount < 1000000000000) {
        return (amount / 1000000000).toFixed(1) + 'B';
    } else {
        return (amount / 1000000000000).toFixed(1) + 'T';
    }
}