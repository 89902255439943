/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { api, errHandler } from "../../../utilities/API";
import Btn from "../../../components/Btn";
import Message from "../../../components/Message";
import { toast } from "react-toastify";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const NewSubscription = () => {
  const validator = require('validator');
  const navigate = useNavigate();
  const durations = [
    'week',
    'month',
    'year'
  ];
  const no_of_days = [
    365,
    180,
    90,
    31,
    30,
    7,
    1,
    0
  ];
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();
  const [icon, setIcon] = useState();

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const isValid = (plan_id, title, description, amount, duration, no_of_days) => {
    if (validator.isEmpty(plan_id)) {
      setAlert({
        title: 'Plan ID is required!',
        message: 'Please enter subscription plan id.',
        type: 'warning',
      });
      return false;
    }
    
    if (validator.isEmpty(title)) {
      setAlert({
        title: 'Title is required!',
        message: 'Please enter subscription title.',
        type: 'warning',
      });
      return false;
    }
    
    if (validator.isEmpty(description)) {
      setAlert({
        title: 'Description is required!',
        message: 'Please enter subscription description.',
        type: 'warning',
      });
      return false;
    }
    
    if (isNaN(parseFloat(amount)) || amount < 0) {
      setAlert({
        title: 'Invalid Amount!',
        message: 'Please enter a valid subscription amount which should not be less than zero.',
        type: 'warning',
      });
      return false;
    }
    
    if (validator.isEmpty(duration) || !durations.includes(duration)) {
      setAlert({
        title: 'Duration is required!',
        message: 'Please select subscription duration.',
        type: 'warning',
      });
      return false;
    }
    
    if (parseInt(no_of_days) <= 0 || !no_of_days.includes(no_of_days)) {
      setAlert({
        title: 'Number of Days is required!',
        message: 'Please enter subscription number of days.',
        type: 'warning',
      });
      return false;
    }

    if (!icon) {
      setAlert({
        title: 'Subscription Icon is Required!',
        message: 'Please upload subscription icon.',
        type: 'warning',
      });
      return false;
    }

    return true;
  };

  const onAddSubscription = async (e) => {
    e.preventDefault();
    const plan_id = e.target['plan_id'].value;
    const title = e.target['title'].value;
    const description = e.target['description'].value;
    const amount = e.target['amount'].value;
    const duration = e.target['duration'].value;
    const no_of_days = e.target['no_of_days'].value;
    const validation = isValid(plan_id, title, description, amount, duration, no_of_days);
    if (validation) {
      setLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const res = await api.post('/admin/subscription/new', { plan_id, title, description, amount, duration, no_of_days, icon }, { headers: { Authorization: `Bearer ${token}` } });
        toast.success(res.data.title + ', ' + res.data.message);
        setTimeout(() => {
          navigate('/dashboard/subscriptions');
        }, 1000);
      } catch (err) {
        await errHandler(err);
        setLoading(false);
      }
    }
  };

  const onUpload = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      setIcon(base64String);
    };

    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  return (
    <div>
      <BreadCrumbs
        links={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Subscriptions", href: "/dashboard/subscriptions" }
        ]}
        current="Add Subscription"
      />
      <br />
      <div className="container border p-4 rounded">
        <form onSubmit={onAddSubscription}>
          <Typography variant="h5" component="h5">
            Create Subscription Plan
          </Typography>
          <br />
          {
            alert && (
              <>
                <Message alert={alert} />
                <br />
              </>
            )
          }
          <fieldset>
          <TextField label="Plan ID" variant="outlined" className="mb-4" name="plan_id" fullWidth required />
          <TextField label="Title" variant="outlined" className="mb-4" name="title" fullWidth required />
          <TextField label="Description" multiline rows={5} variant="outlined" className="mb-4" name="description" fullWidth required />
          <TextField label="Amount" type="number" variant="outlined" className="mb-4" name="amount" fullWidth required />
          <TextField select label="Duration" variant="outlined" className="mb-4" name="duration" fullWidth required>
            {durations.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Number of Days" variant="outlined" className="mb-4" name="no_of_days" fullWidth required>
            {no_of_days.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload Icon
            <VisuallyHiddenInput
              type="file"
              onChange={onUpload}
              multiple
            />
          </Button>
          {
            icon && (
              <Typography variant="body2" component="p">
                Icon has been uploaded
              </Typography>
            )
          }
          <Btn loading={loading} type="submit" className="d-block ml-auto text-right" size="large" variant="contained">Create</Btn>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default NewSubscription;
