import React from "react";
import './Style.css';
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { color } from "../../utilities/Colors";
import Logo from "../../components/Logo";

const Dashboard = () => {

    if (!sessionStorage.getItem('token')) {
        return <Navigate to='/' />
    }

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="main-content" style={{ backgroundColor: color.login_bg, padding: '20px' }}>
                <div className="content bg-white rounded" style={{ padding: '20px', boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

const Sidebar = () => {
    const location = useLocation();
    const pathname = location.pathname;

    const links = [
        {
            label: "Home",
            href: "/dashboard",
            icon: <i className="las la-home"></i>,
            activeLinks: ["/dashboard"],
        },
        {
            label: "Agents",
            href: "/dashboard/agents",
            icon: <i className="las la-users"></i>,
            activeLinks: ["/dashboard/agents", "/dashboard/agents/details"],
        },
        {
            label: "Properties",
            href: "/dashboard/properties",
            icon: <i className="las la-city"></i>,
            activeLinks: ["/dashboard/properties"],
        },
        {
            label: "Property Types",
            href: "/dashboard/properties/types",
            icon: <i className="las la-tasks"></i>,
            activeLinks: ["/dashboard/properties/types"],
        },
        {
            label: "Subscriptions",
            href: "/dashboard/subscriptions",
            icon: <i className="las la-credit-card"></i>,
            activeLinks: ["/dashboard/subscriptions"],
        },
        {
            label: "Privacy Policy",
            href: "/dashboard/privacy_policy",
            icon: <i className="las la-closed-captioning"></i>,
            activeLinks: ["/dashboard/privacy_policy"],
        },
        {
            label: "User Terms",
            href: "/dashboard/terms_of_use",
            icon: <i className="las la-file-code"></i>,
            activeLinks: ["/dashboard/terms_of_use"],
        },
        {
            label: "Admin Queries",
            href: "/dashboard/admin_queries",
            icon: <i className="las la-question-circle"></i>,
            activeLinks: ["/dashboard/admin_queries"],
        },
        {
            label: "Logout",
            href: "/logout",
            icon: <i className="las la-sign-out-alt"></i>,
            activeLinks: ["/logout"],
        },
    ];

    const SidebarItem = ({ href, label, icon, activeLinks }) => {
        return (
            <Link to={href} className={`link ${activeLinks.includes(pathname) ? 'active' : ''}`}>
                {icon}
                {label}
            </Link>
        )
    }
    return (
        <div style={{ backgroundColor: color.sidebar }} className="sidebar">
            <div className="logo">
                <div className="logo-content">
                    <Logo theme='light' />
                </div>
            </div>
            <div className="links">
                {
                    links.map((val, index) => {
                        return (
                            <div key={index}>
                                <SidebarItem label={val.label} href={val.href} icon={val.icon} activeLinks={val.activeLinks} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Dashboard;
