/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { api, errHandler } from "../../../utilities/API";
import Btn from "../../../components/Btn";
import Message from "../../../components/Message";
import { toast } from "react-toastify";

const NewPropertyType = () => {
  const validator = require('validator');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();

  const isValid = (label) => {
    if (validator.isEmpty(label)) {
      setAlert({
        title: 'Label is required!',
        message: 'Please enter your label.',
        type: 'warning',
      }
      );
      return false;
    }

    return true;
  };

  const onAddType = async (e) => {
    e.preventDefault();
    const label = e.target['label'].value;
    const validation = isValid(label);
    if (validation) {
      setLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const res = await api.post('/admin/properties/types/new', { label }, { headers: { Authorization: `Bearer ${token}` } });
        toast.success(res.data.title + ', ' + res.data.message);
        setTimeout(() => {
          navigate('/dashboard/properties/types');
        }, 1000);
      } catch (err) {
        await errHandler(err);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <BreadCrumbs
        links={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Property Types", href: "/dashboard/properties/types" }
        ]}
        current="Add Property"
      />
      <br />
      <div className="container border p-4 rounded">
        <form onSubmit={onAddType}>
          <Typography variant="h5" component="h5">
            Add New Property Type
          </Typography>
          <br />
          {
            alert && (
              <>
                <Message alert={alert} />
                <br />
              </>
            )
          }
          <fieldset>
            <TextField label="Label" variant="outlined" className="mb-4" name="label" fullWidth required />
            <Btn loading={loading} type="submit" className="d-block ml-auto text-right" size="large" variant="contained">Add</Btn>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default NewPropertyType;
