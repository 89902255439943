/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Skeleton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { api, errHandler } from "../../../utilities/API";
import Btn from "../../../components/Btn";
import Message from "../../../components/Message";
import { toast } from "react-toastify";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const EditSubscription = () => {
  const validator = require('validator');
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id');
  const navigate = useNavigate();
  const durations = [
    'week',
    'month',
    'year'
  ];
  const no_of_days = [
    365,
    180,
    90,
    31,
    30,
    7,
    1,
    0
  ];

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();
  const [icon, setIcon] = useState();
  const [details, setDetails] = useState();

  useEffect(() => {
    if (id && !details) loadSubscriptionDetails();
  }, [id]);

  const loadSubscriptionDetails = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/subscription/details?id=' + id, { headers: { Authorization: `Bearer ${token}` } });
      setIcon(res.data?.data.icon);
      setDetails(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadSubscriptionDetails());
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const isValid = (plan_id, title, description, amount, duration, no_of_days) => {
    if (validator.isEmpty(plan_id)) {
      setAlert({
        title: 'Plan ID is required!',
        message: 'Please enter subscription plan id.',
        type: 'warning',
      });
      return false;
    }
    
    if (validator.isEmpty(title)) {
      setAlert({
        title: 'Title is required!',
        message: 'Please enter subscription title.',
        type: 'warning',
      });
      return false;
    }
    
    if (validator.isEmpty(description)) {
      setAlert({
        title: 'Description is required!',
        message: 'Please enter subscription description.',
        type: 'warning',
      });
      return false;
    }
    
    if (isNaN(parseFloat(amount)) || amount < 0) {
      setAlert({
        title: 'Invalid Amount!',
        message: 'Please enter a valid subscription amount which should not be less than zero.',
        type: 'warning',
      });
      return false;
    }
    
    if (validator.isEmpty(duration) || !durations.includes(duration)) {
      setAlert({
        title: 'Duration is required!',
        message: 'Please select subscription duration.',
        type: 'warning',
      });
      return false;
    }
    
    if (parseInt(no_of_days) <= 0 || !no_of_days.includes(no_of_days)) {
      setAlert({
        title: 'Number of Days is required!',
        message: 'Please enter subscription number of days.',
        type: 'warning',
      });
      return false;
    }

    if (!icon) {
      setAlert({
        title: 'Subscription Icon is Required!',
        message: 'Please upload subscription icon.',
        type: 'warning',
      });
      return false;
    }

    return true;
  };

  const onUpdateSubscription = async (e) => {
    e.preventDefault();
    const plan_id = e.target['plan_id'].value;
    const title = e.target['title'].value;
    const description = e.target['description'].value;
    const amount = e.target['amount'].value;
    const duration = e.target['duration'].value;
    const no_of_days = e.target['no_of_days'].value;
    const validation = isValid(plan_id, title, description, amount, duration, no_of_days);
    if (validation) {
      setLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const res = await api.put('/admin/subscription/update', { id, plan_id, title, description, amount, duration, no_of_days, icon }, { headers: { Authorization: `Bearer ${token}` } });
        toast.success(res.data.title + ', ' + res.data.message);
        setTimeout(() => {
          navigate('/dashboard/subscriptions');
        }, 1000);
      } catch (err) {
        await errHandler(err);
        setLoading(false);
      }
    }
  };

  const onUpload = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      setIcon(base64String);
    };

    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  if (!details) {
    return (
      <>
        <Skeleton height={50} width={'50%'} />
        <Skeleton height={50} width={'30%'} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} width={'20%'} className="ml-auto" />
      </>
    )
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    pb: 2
  };

  const deleteSubscription = async () => {
    try {
      setOpen(false);
      const laoding = toast.loading("Please wait...");
      const token = sessionStorage.getItem('token');
      const res = await api.delete('/admin/subscription/delete?id=' + id, { headers: { Authorization: `Bearer ${token}` } });
      toast.update(laoding, { render: res.data.title, position: 'bottom-center', type: "success", isLoading: false, autoClose: true });
      navigate('/dashboard/subscriptions', {replace: true});
    } catch (err) {
      await errHandler(err);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="body1" component="h6" className="text-danger">
            Confirm To Delete The Subscription Plan?
          </Typography>
          <Typography className="text-secondary" sx={{ my: 2 }} variant="body2" component="p">
            Are you sure to delete the current subscription plan? The plan data will be lost.
          </Typography>
          <div className="text-right">
            <Button variant="contained" color="error" onClick={deleteSubscription}>
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
      <BreadCrumbs
        links={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Subscriptions", href: "/dashboard/subscriptions" }
        ]}
        current="Edit Subscription"
      />
      <br />
      <div className="container border p-4 rounded">
        <form onSubmit={onUpdateSubscription}>
          <Typography variant="h5" component="h5">
            Edit Subscription Plan
          </Typography>
          <br />
          {
            alert && (
              <>
                <Message alert={alert} />
                <br />
              </>
            )
          }
          <fieldset>
          <TextField defaultValue={details?.plan_id || "Loading..."} label="Plan ID" variant="outlined" className="mb-4" name="plan_id" fullWidth required />
          <TextField defaultValue={details?.title || "Loading..."} label="Title" variant="outlined" className="mb-4" name="title" fullWidth required />
          <TextField defaultValue={details?.description || "Loading..."} label="Description" multiline rows={5} variant="outlined" className="mb-4" name="description" fullWidth required />
          <TextField defaultValue={details?.amount?.toString() || "Loading..."} label="Amount" type="number" variant="outlined" className="mb-4" name="amount" fullWidth required />
          <TextField defaultValue={details?.duration} select label="Duration" variant="outlined" className="mb-4" name="duration" fullWidth required>
            {durations.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField defaultValue={details?.no_of_days} select label="Number of Days" variant="outlined" className="mb-4" name="no_of_days" fullWidth required>
            {no_of_days.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload Icon
            <VisuallyHiddenInput
              type="file"
              onChange={onUpload}
              multiple
            />
          </Button>
          {
            icon && (
              <Typography variant="body2" component="p">
                Icon has been loaded
              </Typography>
            )
          }
          <div className="d-flex algn-items-center justify-content-end">
            <Button variant="contained" color="error" size="large" className="mr-3" onClick={() => setOpen(true)}>
              Delete
            </Button>
            <Btn loading={loading} type="submit" className="text-right" size="large" variant="contained">Update</Btn>
          </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default EditSubscription;
