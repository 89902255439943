import { Alert } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const Message = ({ alert }) => {
    if (!alert) {
        return;
    }
    return (
        <Alert icon={alert.type === 'success' ? <CheckIcon fontSize="inherit" /> : alert.type === 'warning' ? <QuestionMarkIcon fontSize="inherit" /> : <ClearIcon fontSize="inherit" />} severity={alert.type}>
        <b>{alert.title}</b><br />
        {alert.message}
        </Alert>
    )
}

export default Message;
