import { Button } from "@mui/material";
import React from "react";
import { color } from "../utilities/Colors";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

const Btn = ({ loading, customColor, children, ...props }) => {
  if (loading) {
    return (
      <div {...props}>
        <LoadingButton
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          size={props.size}
          sx={{
            backgroundColor: customColor || color.btn,
            color: color.white,
            "&:hover": {
              backgroundColor: color.btn_hover,
            },
            "&:disabled": {
              color: color.white_50,
            },
          }}
        >
          Please Wait...
        </LoadingButton>
      </div>
    )
  }
  return (
    <Button
      sx={{
        backgroundColor: customColor || color.btn,
        color: color.white,
        "&:hover": {
          backgroundColor: color.btn_hover,
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default Btn;
