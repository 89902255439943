import React from "react";
import "./App.css";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-image-gallery/styles/css/image-gallery.css";

import { ToastContainer } from "react-toastify";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import Login from "./screens/login/Login";
import Dashboard from "./screens/dashboard/Dashboard";
import Home from "./screens/dashboard/home/Home";
import Agents from "./screens/dashboard/agents/Agents";
import AgentDetails from "./screens/dashboard/agent_details/AgentDetails";
import Properties from "./screens/dashboard/properties/Properties";
import PropertyDetails from "./screens/dashboard/property_details/PropertyDetails";
import PropertyTypes from "./screens/dashboard/property_types/PropertyTypes";
import NewPropertyType from "./screens/dashboard/property_types/New";
import Subscription from "./screens/dashboard/subscription/Subscription";
import NewSubscription from "./screens/dashboard/subscription/New";
import EditSubscription from "./screens/dashboard/subscription/Edit";
import PrivacyPolicy from "./screens/dashboard/content/PrivacyPolicy";
import TermsofUse from "./screens/dashboard/content/TermsofUse";
import AdminQueries from "./screens/dashboard/admin_queries/AdminQueries";
import Logout from "./screens/Logout";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'agents',
        element: <Agents />,
      },
      {
        path: 'agents/details',
        element: <AgentDetails />,
      },
      {
        path: 'properties',
        element: <Properties />,
      },
      {
        path: 'properties/details',
        element: <PropertyDetails />,
      },
      {
        path: 'properties/types',
        element: <PropertyTypes />,
      },
      {
        path: 'properties/types/new',
        element: <NewPropertyType />,
      },
      {
        path: 'subscriptions',
        element: <Subscription />,
      },
      {
        path: 'subscriptions/new',
        element: <NewSubscription />,
      },
      {
        path: 'subscriptions/edit',
        element: <EditSubscription />,
      },
      {
        path: 'privacy_policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'terms_of_use',
        element: <TermsofUse />,
      },
      {
        path: 'admin_queries',
        element: <AdminQueries />,
      },
      {
        path: '404',
        element: <h1>404</h1>,
      }
    ],
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: "*",
    element: <Navigate to='/dashboard/404' />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={Router} />
      <ToastContainer />
    </>
  );
}

export default App;
