import { blueGrey } from "@mui/material/colors";

export const color = {
    login_bg: blueGrey[100],
    white: '#ffffff',
    white_50: 'rgba(255,255,255,0.5)',
    sidebar: '#063c59',

    input: blueGrey[900],
    btn: blueGrey[700],
    btn_hover: blueGrey[900]
}