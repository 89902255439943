import { TextField } from "@mui/material";
import { color } from "../utilities/Colors";

export const Input = ({ ...props }) => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-input": {
          color: color.input,
        },
        "& .MuiInputLabel-root": {
          color: color.input,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: color.input,
          },
          "&:hover fieldset": {
            borderColor: color.input,
          },
          "&.Mui-focused fieldset": {
            borderColor: color.input,
          },
        },
      }}
      {...props}
    />
  );
};
