/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React from 'react';
import ImageGallery from "react-image-gallery";

const PropertyImages = ({ images }) => {
    return <ImageGallery items={images} showNav={false} showPlayButton={false} autoPlay={false} />;
}

export default PropertyImages
