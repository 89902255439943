import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { color } from "../utilities/Colors";

const BreadCrumbs = ({ links, current }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {links.map((val, index) => {
        return (
          <Link key={index} underline="hover" style={{color: color.btn, textDecoration: 'none'}} to={val.href}>
            {val.label}
          </Link>
        );
      })}
      <Typography sx={{ color: "text.primary" }}>{current}</Typography>
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
