/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import "./Style.css";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Skeleton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { api, errHandler } from "../../../utilities/API";
import { amountFormat } from "../../../utilities/functions";

const AgentDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const user_id = query.get('user_id');
  const navigate = useNavigate();
  const [details, setDetails] = useState();
  const [list, setlist] = useState();

  useEffect(() => {
    if (user_id && !details) loadAgentDetails();
  }, [user_id]);
  
  useEffect(() => {
    if (details) loadAgentProperties();
  }, [details]);

  const loadAgentDetails = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/agents/details?user_id=' + user_id, { headers: { Authorization: `Bearer ${token}` } });
      setDetails(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadAgentDetails());
    }
  };

  const loadAgentProperties = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get(`/admin/agents/properties?user_id=${user_id}`, { headers: { Authorization: `Bearer ${token}` } });
      setlist(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadAgentProperties());
    }
  };

  return (
    <div>
      <BreadCrumbs
        links={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Agents", href: "/dashboard/agents" },
        ]}
        current="Agent Details"
      />
      <br />
      <br />
      <div className="container-fluid agent_details">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            {
              !details
                ?
                <Skeleton height={300} />
                :
                !details?.profile_image
                ?
                <div className="border p-5 justify-content-center h-100 d-flex align-items-center">No Image Uploaded</div>
                :
                <img
                  src={`${JSON.parse(details?.profile_image)?.prefix}${JSON.parse(details?.profile_image)?.uri}`}
                  alt="agent photo"
                  className="agent_photo"
                />
            }
          </div>
          <div className="col-lg-8 col-md-6 col-sm-12">
            {
              !details
                ?
                <>
                  <Skeleton height={50} width="50%" />
                  <div style={{ display: 'grid', gridTemplateColumns: '50fr 50fr', width: '75%', gridColumnGap: 20 }}>
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                  </div>
                  <div style={{ display: 'grid', gridTemplateColumns: '25fr 25fr 25fr 25fr', gridColumnGap: 20 }}>
                    {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((_, index) => {
                      return <Skeleton height={50} key={index} />
                    })}
                  </div>
                </>
                :
                <>
                  <Typography
                    variant="h4"
                    component="h4"
                    fontWeight={700}
                    className="text-uppercase"
                  >
                    {details?.full_name}
                  </Typography>
                  <div className="d-flex" style={{ gap: 20 }}>
                    <div className="d-flex align-items-center" style={{ gap: 5 }}>
                      <i className="las la-phone"></i>
                      <Typography variant="body1" component="p">
                        {details?.phone}
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center" style={{ gap: 5 }}>
                      <i className="las la-envelope-open-text"></i>
                      <Typography variant="body1" component="p">
                        {details?.email}
                      </Typography>
                    </div>
                  </div>
                  <br />
                  <table className="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <th>
                          <Typography variant="body2" fontWeight={500} component="p">
                            license Number
                          </Typography>
                        </th>
                        <td>
                          <Typography variant="body2" fontWeight={300} component="p">
                            {details?.license_number || '---'}
                          </Typography>
                        </td>
                        <th>
                          <Typography variant="body2" fontWeight={500} component="p">
                            broker Name
                          </Typography>
                        </th>
                        <td className="text-capitalize">
                          <Typography variant="body2" fontWeight={300} component="p">
                            {details?.broker_name || '---'}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Typography variant="body2" fontWeight={500} component="p">
                            Is Profile Completed
                          </Typography>
                        </th>
                        <td>
                          <Typography variant="body2" fontWeight={300} component="p">
                            {details?.is_profile_completed ? 'Y' : 'N'}
                          </Typography>
                        </td>
                        <th>
                          <Typography variant="body2" fontWeight={500} component="p">
                            Is Subscription Activated
                          </Typography>
                        </th>
                        <td>
                          <Typography variant="body2" fontWeight={300} component="p">
                            {details?.is_subscription_activated ? 'Y' : 'N'}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Typography variant="body2" fontWeight={500} component="p">
                            Number of Properties
                          </Typography>
                        </th>
                        <td>
                          <Typography variant="body2" fontWeight={300} component="p">
                            {details?.no_of_properties}
                          </Typography>
                        </td>
                        <th>
                          <Typography variant="body2" fontWeight={500} component="p">
                            Status
                          </Typography>
                        </th>
                        <td className="text-capitalize">
                          <Typography variant="body2" fontWeight={300} component="p">
                            {details?.is_profile_completed && details?.is_subscription_activated ? details?.status : "Incomplete"}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Typography variant="body2" fontWeight={500} component="p">
                            Loaction
                          </Typography>
                        </th>
                        <td className="text-capitalize" colSpan={3}>
                          <Typography variant="body2" fontWeight={300} component="p">
                            {details?.location || '------'}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
            }
          </div>
        </div>
      </div>
      <br />
      <br />
      {
        !details
        ?
        <Skeleton height={80} width="50%" />
        :
        <Typography variant="h4" component="h4" className="text-uppercase" fontWeight={900}>
          Properties Listed By {details?.full_name.split(' ').shift()}
        </Typography>
      }
      <br />
      {
        !details || !list
        ?
        <div style={{ display: 'grid', gridTemplateColumns: '20fr 20fr 20fr 20fr 20fr', gridColumnGap: 20 }}>
            {[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1].map((_, index) => {
              return <Skeleton height={50} key={index} />
            })}
        </div>
        :
        list.length === 0
        ?
        <Typography variant="h6" component="h6" align="center">
          No Property Uploaded
        </Typography>
        :
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  Sr.No
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  title
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  location
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  address
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  agent
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  property description
                </Typography>
              </th>
              <th className="text-capitalize">
                <Typography variant="body2" fontWeight={500} component="p">
                  status
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              list.map((val, index) => {
                return (
                  <tr
                    key={index}
                    className="pointer pointer-hover"
                    onClick={() => navigate("/dashboard/properties/details?id=" + val.id)}
                  >
                    <td>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {index + 1}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {val.title}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {val.city}
                      </Typography>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {val.state}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {val.address}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {
                          val.agent_percentage === 0
                          ?
                          <>
                            ${amountFormat(val.agent_amount)}/-
                          </>
                          :
                          <>
                            {val.agent_percentage}%
                          </>
                        }
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {val.property_description}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2" fontWeight={300} component="p">
                        {val.status}
                      </Typography>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      }
    </div>
  );
};

export default AgentDetails;
