/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { api, errHandler } from "../../../utilities/API";
import { amountFormat } from "../../../utilities/functions";
import Btn from "../../../components/Btn";

const Subscription = () => {
  const navigate = useNavigate();
  const [list, setlist] = useState();
  
  useEffect(() => {
    loadSubscriptions();
  }, []);

  const loadSubscriptions = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/subscription/listing', { headers: { Authorization: `Bearer ${token}` } });
      setlist(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadSubscriptions());
    }
  };

  return (
    <div>
      <BreadCrumbs
        links={[{ label: "Dashboard", href: "/dashboard" }]}
        current="Subscriptions"
      />
      <br />
      <div className="d-flex align-items-center justify-content-between">
        <Typography variant="h5" component="h5">
          All Subscription Plans
        </Typography>
        <Btn variant="contained" onClick={() => navigate('new')}>New</Btn>
      </div>
      <br />
      {
        list && list.length === 0
        ?
        <Typography variant="h5" component="h5" align="center">
          No Subscription Plan Available
        </Typography>
        :
        <div style={{ display: 'grid', gridTemplateColumns: '33fr 33fr 33fr', gridGap: 20 }}>
          {
            !list
            ?
            [1,1,1,1,1,1,1,1,1].map((_, index) => {
              return <Skeleton height={300} key={index} />;
            })
            :
            list?.map((val, index) => {
              return (
                <Card key={index} className="pointer" onClick={() => navigate(`edit?id=${val.subscription_id}`)}>
                  <CardMedia
                    sx={{ height: 200 }}
                    image={val?.icon}
                    title="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {val?.title}
                    </Typography>
                    <Typography variant="body1" className="text-capitalize">
                      Status: <span className={val?.status === 'active' ? 'text-success' : 'text-danger'}>{val?.status}</span>
                    </Typography>
                    <div className="d-flex mb-2" style={{gap: 10}}>
                      <Typography variant="body2" className="text-capitalize"><b>Price:</b> ${amountFormat(val?.amount)}/{val?.duration}</Typography>
                      <Typography variant="body2" className="text-capitalize"><b>Duration:</b> {val?.no_of_days} days</Typography>
                    </div>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {val?.description}
                    </Typography>
                  </CardContent>
                </Card>
              )
            })
          }
        </div>
      }
    </div>
  );
};

export default Subscription;
