/* eslint-disable react-hooks/exhaustive-deps */
import {
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { api, errHandler } from "../../../utilities/API";
import { amountFormat } from "../../../utilities/functions";

const Properties = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [list, setlist] = useState();
  
  useEffect(() => {
    loadProperties();
  }, [page]);

  const loadProperties = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/properties/listing?page=' + page, { headers: { Authorization: `Bearer ${token}` } });
      setlist(res.data?.data[0]);
      setMaxPage(res.data?.data[1]);
    } catch (err) {
      await errHandler(err, () => loadProperties());
    }
  };

  return (
    <div>
      <BreadCrumbs
        links={[{ label: "Dashboard", href: "/dashboard" }]}
        current="Properties"
      />
      <br />
      <Typography variant="h5" component="h5">
        All Properties
      </Typography>
      <br />
      {
        !list
        ?
        <div style={{ display: 'grid', gridTemplateColumns: '33fr 33fr 33fr', gridColumnGap: 20 }}>
            {[1,1,1,1,1,1,1,1,1].map((_, index) => {
              return <Skeleton height={50} key={index} />
            })}
        </div>
        :
        list.length === 0
        ?
        <Typography variant="h5" component="h5" align="center">
          No Property is Added
        </Typography>
        :
        <>
          <table className="table table-bordered">
            <thead>
              <tr>
              <th className="text-capitalize">
                  <Typography variant="body2" fontWeight={500} component="p">
                    Sr.No
                  </Typography>
                </th>
                <th className="text-capitalize">
                  <Typography variant="body2" fontWeight={500} component="p">
                    title
                  </Typography>
                </th>
                <th className="text-capitalize">
                  <Typography variant="body2" fontWeight={500} component="p">
                    location
                  </Typography>
                </th>
                <th className="text-capitalize">
                  <Typography variant="body2" fontWeight={500} component="p">
                    address
                  </Typography>
                </th>
                <th className="text-capitalize">
                  <Typography variant="body2" fontWeight={500} component="p">
                    agent
                  </Typography>
                </th>
                <th className="text-capitalize">
                  <Typography variant="body2" fontWeight={500} component="p">
                    property description
                  </Typography>
                </th>
                <th className="text-capitalize">
                  <Typography variant="body2" fontWeight={500} component="p">
                    status
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((val, index) => (
                <tr key={index} className="pointer pointer-hover" onClick={() => navigate("/dashboard/properties/details?id=" + val.id)}>
                  <td>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {index + 1}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {val.title}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {val.city}
                    </Typography>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {val.state}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {val.address}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {
                        val.agent_percentage === 0
                          ?
                          <>
                            ${amountFormat(val.agent_amount)}/-
                          </>
                          :
                          <>
                            {val.agent_percentage}%
                          </>
                      }
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {val.property_description}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" fontWeight={300} component="p">
                      {val.status}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-center flex-wrap w-75 mx-auto">
            {
              Array.from({ length: maxPage }, (_, i) => i).map((_, index) => {
                return (
                  <div className="text-center mx-3 pointer mb-3" onClick={() => setPage(index + 1)} style={{ lineHeight: 1 }}>
                    <p className="mb-0">{index + 1}</p>
                    <small style={{ fontSize: 10 }}>Page</small>
                  </div>
                )
              })
            }
          </div>
        </>
      }
    </div>
  );
};

export default Properties;
