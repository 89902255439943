import React, { useState } from "react";
import './Style.css';
import { color } from "../../utilities/Colors";
import { Typography } from "@mui/material";
import { Input } from "../../components/Input";
import Btn from "../../components/Btn";
import Logo from "../../components/Logo";
import { Navigate, useNavigate } from "react-router-dom";
import { api, errHandler } from "../../utilities/API";
import Message from "../../components/Message";
import { toast } from "react-toastify";

const Login = () => {
  const validator = require('validator');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();

  const isValid = (username, password) => {
    if (validator.isEmpty(username)) {
      setAlert({
        title: 'Username is required!',
        message: 'Please enter your username.',
        type: 'warning',
      }
      );
      return false;
    }

    if (validator.isEmpty(password)) {
      setAlert({
        title: 'Password is required!',
        message: 'Please enter your password.',
        type: 'warning',
      }
      );
      return false;
    }

    return true;
  };

  const onLogin = async (e) => {
    e.preventDefault();
    const username = e.target['username'].value;
    const password = e.target['password'].value;
    const validation = isValid(username, password);

    if (validation) {
      setLoading(true);

      try {
        const res = await api.post('/auth/admin/login', {
          username: username,
          password: password,
        });
        toast.success(res.data.title + ', ' + res.data.message);
        sessionStorage.setItem('token', res.data.data.token);
        setTimeout(() => {
          navigate('/dashboard', {replace: true});
        }, 2000);
      } catch (err) {
        await errHandler(err);
      }
      setLoading(false);
    }
  }

  if (sessionStorage.getItem('token')) {
    return <Navigate to='/dashboard' />
  }

  return (
    <div
      className="w-100 vh-100 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: color.login_bg }}
    >
      <div className="shadow-sm bg-white login-screen-content rounded">
        <Logo />
        <br />
        <Typography variant="h5" component="h5" align="center">
          Login
        </Typography>
        <br />
        {
          alert && (
            <>
              <Message alert={alert} />
              <br />
            </>
          )
        }
        <form onSubmit={onLogin}>
          <fieldset>
            <Input
              label="Username"
              variant="outlined"
              name="username"
              className="mb-3"
              fullWidth
              required
            />
            <Input
              label="Password"
              variant="outlined"
              name="password"
              className="mb-4"
              type="password"
              fullWidth
              required
            />
            <Btn loading={loading} type="submit" className="d-block mx-auto text-center" size="large" variant="contained">Submit</Btn>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default Login;
