/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import "./Style.css";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Skeleton, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { api, baseUrl, errHandler } from "../../../utilities/API";
import { amountFormat } from "../../../utilities/functions";
import PlaceIcon from '@mui/icons-material/Place';
import PropertyImages from "./PropertyImages";
import FolderIcon from '@mui/icons-material/Folder';
import { toast } from "react-toastify";

const PropertyDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id');
  const [images, setImages] = useState();
  const [dcouments, setDcouments] = useState();
  const [details, setDetails] = useState();

  useEffect(() => {
    if (id && !details) loadPropertyDetails();
  }, []);

  useEffect(() => {
    if (details) loadPropertyDocuments();
  }, [details]);

  const loadPropertyDetails = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/properties/details?id=' + id, { headers: { Authorization: `Bearer ${token}` } });
      setDetails(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadPropertyDetails());
    }
  };

  const loadPropertyDocuments = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get(`/admin/properties/documents?id=${id}`, { headers: { Authorization: `Bearer ${token}` } });
      const images = [];
      for (let x = 0; x < res.data.data[0].length; x++) {
        images.push({
          original: `${baseUrl}/images/properties/${res.data.data[0][x].url}`,
          thumbnail: `${baseUrl}/images/properties/${res.data.data[0][x].url}`,
        })
      }
      setImages(images);
      setDcouments(res.data.data[1]);
    } catch (err) {
      await errHandler(err, () => loadPropertyDocuments());
    }
  };

  const downloadDocument = (url) => {
    window.open(`${baseUrl}/download?file=${url}`, '_blank');
    toast.success('Document has been downloaded!', { position: 'bottom-center' });
  }

  return (
    <div>
      <BreadCrumbs
        links={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Properties", href: "/dashboard/properties" },
        ]}
        current="Property Details"
      />
      <br />
      <br />
      <div className="container-fluid agent_details">
        <div className="row">
          {
            images && (
              <>
                <div className="col-3 mb-3"></div>
                <div className="col-6 mb-3">
                  <PropertyImages images={images} />
                </div>
                <div className="col-3 mb-3"></div>
              </>
            )
          }
          <div className="col-12 mb-3">
            {
              !details
                ?
                <Skeleton height={50} width='30%' />
                :
                <Typography variant="h5" component="h5" className="text-uppercase">
                  {details?.title}
                </Typography>
            }
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            {
              !details
                ?
                <>
                  <Skeleton height={30} />
                  <Skeleton height={50} />
                </>
                :
                <>
                  <Typography variant="body2" component="p" className="text-secondary">Property Price</Typography>
                  <Typography variant="h5" component="h5" className="text-uppercase">
                    ${amountFormat(details?.property_value)}
                  </Typography>
                </>
            }
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="d-flex flex-wrap" style={{ gap: 20 }}>
              <div style={{ flexGrow: 1 }}>
                {
                  !details
                    ?
                    <>
                      <Skeleton height={30} />
                      <Skeleton height={50} />
                    </>
                    :
                    <>
                      <Typography variant="body2" component="p" className="text-secondary">No of Bathrooms</Typography>
                      <Typography variant="h5" component="h5" className="text-uppercase">
                        {details?.no_of_bathrooms}
                      </Typography>
                    </>
                }
              </div>
              <div style={{ flexGrow: 1 }}>
                {
                  !details
                    ?
                    <>
                      <Skeleton height={30} />
                      <Skeleton height={50} />
                    </>
                    :
                    <>
                      <Typography variant="body2" component="p" className="text-secondary">No of Bedrooms</Typography>
                      <Typography variant="h5" component="h5" className="text-uppercase">
                        {details?.no_of_bathrooms}
                      </Typography>
                    </>
                }
              </div>
            </div>
          </div>
          <div className="col-12 my-4">
            <div className="shadow p-3 rounded d-flex" style={{ gap: 20 }}>
              {
                !details
                  ?
                  <>
                    <Skeleton height={50} width={100} />
                    <Skeleton height={50} width='100%' />
                  </>
                  :
                  <>
                    <PlaceIcon />
                    <Typography variant="body2" component="p" className="text-secondary mb-0 mt-1">
                      {details?.address}
                    </Typography>
                  </>
              }
            </div>
          </div>
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  {
                    details?.agent_percentage === 0
                      ?
                      <td>
                        {
                          !details
                            ?
                            <>
                              <Skeleton height={30} />
                              <Skeleton height={50} />
                            </>
                            :
                            <>
                              <Typography variant="body2" component="p" className="text-secondary">Agent's Amount</Typography>
                              <Typography variant="h5" component="h5" className="text-capitalize">
                                {amountFormat(details?.agent_amount)}
                              </Typography>
                            </>
                        }
                      </td>
                      :
                      <td>
                        {
                          !details
                            ?
                            <>
                              <Skeleton height={30} />
                              <Skeleton height={50} />
                            </>
                            :
                            <>
                              <Typography variant="body2" component="p" className="text-secondary">Agent's Percentage</Typography>
                              <Typography variant="h5" component="h5" className="text-capitalize">
                                {details?.agent_percentage}%
                              </Typography>
                            </>
                        }
                      </td>
                  }
                  <td>
                    {
                      !details
                        ?
                        <>
                          <Skeleton height={30} />
                          <Skeleton height={50} />
                        </>
                        :
                        <>
                          <Typography variant="body2" component="p" className="text-secondary">City</Typography>
                          <Typography variant="h5" component="h5" className="text-capitalize">
                            {details?.city}
                          </Typography>
                        </>
                    }
                  </td>
                  <td>
                    {
                      !details
                        ?
                        <>
                          <Skeleton height={30} />
                          <Skeleton height={50} />
                        </>
                        :
                        <>
                          <Typography variant="body2" component="p" className="text-secondary">State</Typography>
                          <Typography variant="h5" component="h5" className="text-capitalize">
                            {details?.state}
                          </Typography>
                        </>
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    {
                      !details
                        ?
                        <>
                          <Skeleton height={30} />
                          <Skeleton height={50} />
                        </>
                        :
                        <>
                          <Typography variant="body2" component="p" className="text-secondary">Property Size</Typography>
                          <Typography variant="h5" component="h5" className="text-capitalize">
                            {details?.property_size} Sq
                          </Typography>
                        </>
                    }
                  </td>
                  <td>
                    {
                      !details
                        ?
                        <>
                          <Skeleton height={30} />
                          <Skeleton height={50} />
                        </>
                        :
                        <>
                          <Typography variant="body2" component="p" className="text-secondary">Property Type</Typography>
                          <Typography variant="h5" component="h5" className="text-capitalize">
                            {details?.tbl_property_type?.label}
                          </Typography>
                        </>
                    }
                  </td>
                  <td>
                    {
                      !details
                        ?
                        <>
                          <Skeleton height={30} />
                          <Skeleton height={50} />
                        </>
                        :
                        <>
                          <Typography variant="body2" component="p" className="text-secondary">Status</Typography>
                          <Typography variant="h5" component="h5" className={"text-capitalize " + (details?.status === 'deleted' ? 'text-danger' : '')}>
                            {details?.status}
                          </Typography>
                        </>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 mb-5">
            {
              !details
                ?
                <>
                  <Skeleton height={30} />
                  <Skeleton height={50} />
                </>
                :
                <>
                  <Typography variant="h5" component="h5" className="text-uppercase mb-2">
                    Property Description
                  </Typography>
                  <Typography variant="body2" component="p" className="text-capitalize text-secondary">
                    {details?.property_description}
                  </Typography>
                </>
            }
          </div>
          <div className="col-12 mb-5">
            {
              !details
                ?
                <Skeleton height={50} width='30%' />
                :
                <>
                  <Typography variant="h5" component="h5" className="text-uppercase mb-2">
                    Property Documents
                  </Typography>
                </>
            }
            <div style={{ display: 'grid', gridTemplateColumns: '33fr 33fr 33fr', gridColumnGap: '20px' }}>
              {
                !dcouments
                  ?
                  [1, 1, 1, 1, 1, 1, 1, 1, 1].map((_, index) => {
                    return <Skeleton height={50} key={index} />
                  })
                  :
                  dcouments?.map((val, index) => {
                    return (
                      <div onClick={() => downloadDocument(val?.url)} key={index} className="border rounded p-3 d-flex align-items-center pointer" style={{ gap: 15 }}>
                        <FolderIcon />
                        <Typography variant="body2" component="p" className="text-capitalize text-secondary">
                          {val.url}
                        </Typography>
                      </div>
                    )
                  })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
