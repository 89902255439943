import axios from 'axios';
import { toast } from 'react-toastify';

// export const baseUrl = 'http://192.168.1.21:8080';
export const baseUrl = 'https://agentspay.predemo.site';
export const api = axios.create({
    baseURL: baseUrl,
});

export const errHandler = async (err, callback) => {
    const status = err?.response?.status;
    if (status === 417 || status === 500 || status === 406 || status === 502 || status === 401)
    {
        // 417 VALIDATION ERROR
        // 417 INTERNAL SERVER/DATABASE ERROR
        // 500 INTERNAL SERVER/DATABASE ERROR
        // 406 NOT ACCEPTABLE
        // 502 BAD GATEWAY
        // 401 UNAUTHORIZED
        toast.error(err.response.data?.title + ', ' + err.response.data?.message);
    }else
    if (status === 404) // NOT FOUND
    {
        const calledAPI = err?.response?.config?.url;
        toast.error('Unknown API Called, Please make sure that the API (' + calledAPI + ") you're calling is already exists!");
    }else
    if (status === 511) // MALFORMED TOKEN
    {
        toast.error('Your session has been ended!!');
    }else {
        if (callback) {
            toast.error('Slow internet detected. Retrying....');
            callback();
        }else {
            toast.error('Slow internet detected. Please try again!');
        }
    }
};
