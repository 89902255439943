/* eslint-disable react-hooks/exhaustive-deps */
import {
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { api, errHandler } from "../../../utilities/API";
import Btn from "../../../components/Btn";
import Message from "../../../components/Message";
import { toast } from "react-toastify";

const PrivacyPolicy = () => {
  const validator = require('validator');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();
  const [policy, setContent] = useState();
  const isMounted = useRef(false);
  
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true; // Mark as mounted
      loadContent();
    }
  }, []);

  const loadContent = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await api.get('/admin/content/privacy_policy', { headers: { Authorization: `Bearer ${token}` } });
      setContent(res.data?.data);
    } catch (err) {
      await errHandler(err, () => loadContent());
    }
  };

  const isValid = (content) => {
    if (validator.isEmpty(content)) {
      setAlert({
        title: 'Content is required!',
        message: 'Please enter your content.',
        type: 'warning',
      }
      );
      return false;
    }

    return true;
  };

  const onUpdateContent = async (e) => {
    e.preventDefault();
    const content = e.target['content'].value;
    const validation = isValid(content);
    if (validation) {
      setLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const res = await api.put('/admin/content/privacy_policy/update', { content }, { headers: { Authorization: `Bearer ${token}` } });
        toast.success(res.data.message);
        loadContent();
      } catch (err) {
        await errHandler(err);
      }
      setLoading(false);
    }
  };

  if (!policy) {
    return (
      <>
        <Skeleton height={50} width={'50%'} />
        <Skeleton height={50} width={'30%'} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} width={'20%'} className="ml-auto" />
      </>
    )
  }

  return (
    <div>
      <BreadCrumbs
        links={[
          { label: "Dashboard", href: "/dashboard" },
        ]}
        current="Privacy Policy"
      />
      <br />
      <div className="container border p-4 rounded">
        <form onSubmit={onUpdateContent}>
          <Typography variant="h5" component="h5">
            Privacy Policy Content
          </Typography>
          <br />
          {
            alert && (
              <>
                <Message alert={alert} />
                <br />
              </>
            )
          }
          <fieldset>
            <TextField defaultValue={policy.content} multiline rows={20} label="Privacy Policy Content" variant="outlined" className="mb-4" name="content" fullWidth required />
            <Btn loading={loading} type="submit" className="d-block ml-auto text-right" size="large" variant="contained">Update Content</Btn>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
