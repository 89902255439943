import { Typography } from "@mui/material";
import React from "react";

const Home = () => {
  return (
    <div>
        <Typography variant="h5" component="h5" align="center">
          Welcome To Agents Pay Dashboard
        </Typography>
    </div>
  );
};

export default Home;
