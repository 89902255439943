/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    sessionStorage.removeItem('token');
    navigate('/');
  }, []);

  return <></>;
};

export default Logout;
